import { API_HOST } from '~/shared/api'
import axios from 'axios'
import Cookies from 'js-cookie'

export async function createPersonalTask(imageFile, mode, gaClientId) {
	const formData = new FormData()
	formData.append('image', imageFile)

	if (mode === 'SEX') {
		formData.append('preset', 'UNDRESS')
	} else {
		formData.append('preset', mode)
	}

	if (gaClientId) {
		formData.append('gaClientId', gaClientId)
	}

	const response = await axios.post(
		`${API_HOST}/task/createPersonal`,
		formData,
		{
			headers: {
				'Content-Type': 'multipart/form-data',
				Authorization: `Bearer ${Cookies.get('token')?.toString()}`,
			},
		}
	)

	return response.data
}
