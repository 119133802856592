import { API_HOST, api } from '~/shared/api'
import { AxiosResponse } from 'axios'

interface TaskCreationResponse {
	estimated_wait_time: number
	queue_pos: number
	status: 'IN_QUEUE' | 'IN_PROGRESS' | 'FAILED' | 'FINISHED' | 'CANCELLED'
}

export async function createPersonalTask(
	imageFile: File,
	mode: string
): Promise<TaskCreationResponse> {
	const formData = new FormData()
	formData.append('image', imageFile)
	formData.append('preset', mode)

	const response: AxiosResponse<TaskCreationResponse> =
		await api.post<TaskCreationResponse>(
			`${API_HOST}/task/createPersonal`,
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			}
		)

	return response.data
}

export async function markCurrentTaskAsViewed() {
	const response = await api.post('/task/markCurrentAsViewed', {})

	return response.data
}
